<template>
  <div class="d3-my-cloth-statistics">
    <div class="chart">
      <div class="y-axis">
        <svg ref="chart-y-axis" :width="margin.left" :height="height"></svg>
      </div>
      <div class="chart-body" ref="chart">
      </div>
      <div class="y-axis">
        <svg ref="chart-y-axis-right" :width="margin.right" :height="height"></svg>
      </div>
    </div>
    <div class="chart-mark-list">
      <div class="chart-mark-item">
        <div class="chart-mark-dot add"></div>
        <span class="fs-12px fw-bold">新增</span>
      </div>
      <div class="chart-mark-item">
        <div class="chart-mark-dot out"></div>
        <span class="fs-12px fw-bold">淘汰</span>
      </div>
      <div class="chart-mark-item">
        <div class="chart-mark-dot all"></div>
        <span class="fs-12px fw-bold">總量</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import observeVisibilityMixin from "@/assets/js/observeVisibilityMixin";

export default {
  name: 'D3MyClothStatistics',
  mixins: [observeVisibilityMixin],
  data() {
    return {
      isVisible: false,
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 450,
    },
    height: {
      type: Number,
      default: 150,
    },
    duration: {
      type: Number,
      default: 2000,
    },
    margin: {
      type: Object,
      default: () => ({ top: 16, right: 36, bottom: 36, left: 36 }),
    },
  },
  components: {
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.drawChart(false);
    this.useObserver(
        this.$el,
        () => {
          this.isVisible = true;
          this.drawChart(true);
        }
    );
  },
  methods: {
    drawChart(animate) {
      const { data, width, height, duration, margin } = this;
      const validData = data.map((d) => ({
        ...d,
        month: String(d.month), // X軸標線項目為數字時, 在跑折線動畫可能會出現無法正確定位的NaN bug, 所以必須確保 month 是字符串
      }));

      const chartWidth = width - margin.left - margin.right;
      const chartHeight = height - margin.top - margin.bottom;

      // 繪製主圖表
      const chartContainer = d3.select(this.$refs.chart);
      chartContainer.selectAll("*").remove(); // 清空容器

      const svg = chartContainer
          .append("svg")
          .attr("width", chartWidth)
          .attr("height", chartHeight + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(0, ${margin.top})`);

      // 定義縮放比例
      const x0 = d3
          .scaleBand()
          .domain(validData.map((d) => d.month))
          .range([0, chartWidth])
          .padding(0.2);

      const x1 = d3
          .scaleBand()
          .domain(["new", "out"])
          .range([0, x0.bandwidth()])
          .padding(0.05);

      const y = d3
          .scaleLinear()
          .domain([0, d3.max(validData, (d) => Math.max(d.new, d.out, d.all))]) // Include 'all' for the max y-axis range
          .nice()
          .range([chartHeight, 0]);

      // 定義顏色
      const color = d3.scaleOrdinal().domain(["new", "out"]).range(["#F7D88F", "#B1D8BD"]);

      // 繪製柱狀圖
      const bars = svg
          .selectAll(".group")
          .data(validData)
          .join("g")
          .attr("transform", (d) => `translate(${x0(d.month)},0)`)
          .selectAll("rect")
          .data((d) => ["new", "out"].map((key) => ({ key, value: d[key] })))
          .join("rect")
          .attr("x", (d) => x1(d.key))
          .attr("width", x1.bandwidth())
          .attr("fill", (d) => color(d.key));

      if (animate) {
        bars
            .attr("y", chartHeight)
            .attr("height", 0)
            .transition()
            .duration(duration)
            .attr("y", (d) => y(d.value))
            .attr("height", (d) => chartHeight - y(d.value));
      } else {
        bars.attr("y", () => y(0)).attr("height", 0); // 初始靜止
      }

      // 繪製折線圖
      const line = d3
          .line()
          .x((d) => x0(d.month) + x0.bandwidth() / 2)
          .y(() => chartHeight); // 初始位置為圖表底部

      const finalLine = d3
          .line()
          .x((d) => x0(d.month) + x0.bandwidth() / 2)
          .y((d) => y(d.all)); // 最終值

      const path = svg
          .append("path")
          .datum(validData)
          .attr("fill", "none")
          .attr("stroke", "#E96D45")
          .attr("stroke-width", 2)
          .attr("d", line);

      if (animate) {
        path
            .transition()
            .duration(duration)
            .attrTween("d", function () {
              const interpolate = d3.interpolateArray(
                  validData.map((d) => ({ month: d.month, all: 0 })),
                  validData
              );
              return (t) => finalLine(interpolate(t));
            });
      }

      // 繪製可操作點
      const dots = svg
          .selectAll(".dot")
          .data(validData)
          .join("circle")
          .attr("cx", (d) => x0(d.month) + x0.bandwidth() / 2)
          .attr("cy", chartHeight) // 初始位置
          .attr("r", 4)
          .attr("fill", "white")
          .attr("stroke", "#E96D45")
          .attr("stroke-width", 2);

      if (animate) {
        dots
            .transition()
            .duration(duration)
            .attr("cy", (d) => y(d.all))
            .attr("class", "dot").end()
            .then(() => {
              // 添加交互
              const circles = svg.selectAll(".dot");

              // 設置預設選中第一個點
              const firstCircle = circles.filter((_, i) => i === 0);
              firstCircle.attr("fill", "#E96D45").attr("data-selected", "true");

              // 顯示第一個點的數值
              svg
                  .append("text")
                  .attr("class", "value-text")
                  .attr("x", +firstCircle.attr("cx"))
                  .attr("y", +firstCircle.attr("cy") - 10)
                  .attr("text-anchor", "middle")
                  .attr("fill", "#E96D45")
                  .text(validData[0].all);

              // 點擊功能
              circles.on("click", function (event, d) {
                const circle = d3.select(this);
                const isSelected = circle.attr("data-selected") === "true";

                // 清空所有圓點的狀態
                svg.selectAll(".dot").attr("fill", "white").attr("data-selected", "false");
                svg.selectAll(".value-text").remove();

                if (!isSelected) {
                  // 將當前點設為選中
                  circle.attr("fill", "#E96D45").attr("data-selected", "true");

                  // 添加數字標籤
                  svg
                      .append("text")
                      .attr("class", "value-text")
                      .attr("x", +circle.attr("cx"))
                      .attr("y", +circle.attr("cy") - 10)
                      .attr("text-anchor", "middle")
                      .attr("fill", "#E96D45")
                      .text(d.all);
                }
              });
            });
      }

      // 繪製X軸
      const xAxisGroup = svg
          .append("g")
          .attr("transform", `translate(0,${chartHeight})`);

      // 定義 X 軸並繪製
      const xAxis = d3.axisBottom(x0).tickFormat(() => ""); // 暫時不設置文字
      xAxisGroup.call(xAxis);

      // 自定義月份與年份標籤
      xAxisGroup.selectAll(".tick").each(function (d, i) {
        const currentData = validData[i];
        const prevData = validData[i - 1];

        // 當前的 tick 元素
        const tick = d3.select(this);

        // 添加月份
        const textElement = tick
            .append("text")
            .attr("text-anchor", "middle")
            .attr("fill", "#6F6F6F")
            .style("font-size", "12px")
            .style("font-weight", "bold");

        textElement
            .append("tspan")
            .text(`${d}月`) // 顯示月份
            .attr("x", 0)
            .attr("dy", "1.1em"); // 確保垂直居中

        // 如果是每年的第一個月份，添加年份
        if (!prevData || currentData.year !== prevData.year) {
          textElement
              .append("tspan")
              .text(`${currentData.year}`) // 顯示年份
              .attr("x", 0)
              .attr("dy", "1.2em"); // 添加年份時向下偏移
        }
      });

      // 隱藏標線
      xAxisGroup.selectAll("line").style("display", "none");

      // 修改主軸線顏色並移除兩端延伸
      xAxisGroup.selectAll("path")
          .style("stroke", "#C6C6C6") // 設置主軸線顏色
          .attr("d", function () {
            const originalD = d3.select(this).attr("d"); // 獲取當前的 path 路徑
            // 移除垂直段
            let modifiedD = originalD.replace(/V\s*\d+/g, ""); // 去除垂直段
            // 修改M命令的y坐標，將其設置為期望的值（例如y=0）
            modifiedD = modifiedD.replace(/M\d+,(\d+)/, () => `M0,0`); // 這裡是將 y 坐標設置為 0
            return modifiedD; // 返回修改後的路徑
          });

      // 修改文字樣式
      xAxisGroup.selectAll("text")
          .style("font-size", "12px")
          .style("line-height", "14px")
          .style("font-weight", "bold")
          .style("fill", "#6F6F6F");


      // 繪製 Y 軸，保持固定
      const yAxisSvg = d3.select(this.$refs["chart-y-axis"]);
      const yAxisSvgRight = d3.select(this.$refs["chart-y-axis-right"]);
      yAxisSvg.selectAll("*").remove();
      yAxisSvgRight.selectAll("*").remove(); // 清空Y軸容器

      // 在左側 Y 軸繪製
      const yAxisGroupLeft = yAxisSvg
          .attr("width", margin.left)
          .attr("height", chartHeight + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(${margin.left - 1}, ${margin.top})`);

      // 定義左側 Y 軸
      const yAxis = d3.axisLeft(y)
          .tickFormat((d) => d === 0 ? "" : `${d}件`)  // 隱藏 0 的文字
          .ticks(6);

      yAxisGroupLeft.call(yAxis);

      // 隱藏標線和主軸線
      yAxisGroupLeft.selectAll("line").style("display", "none"); // 隱藏標線
      yAxisGroupLeft.selectAll("path").style("display", "none"); // 隱藏主軸線

      // 修改文字樣式
      yAxisGroupLeft.selectAll("text")
          .style("font-size", "12px")
          .style("fill", "#6F6F6F")
          .attr("dx", "0.4em"); // 設置字體樣式

      // 在右側 Y 軸繪製
      const yAxisGroupRight = yAxisSvgRight
          .attr("width", margin.right)
          .attr("height", chartHeight + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(14, ${margin.top})`); // 右側 Y 軸，稍微偏移

      yAxisGroupRight.call(yAxis);

      // 隱藏標線和主軸線
      yAxisGroupRight.selectAll("line").style("display", "none"); // 隱藏標線
      yAxisGroupRight.selectAll("path").style("display", "none"); // 隱藏主軸線

      // 修改右側 Y 軸的文字樣式
      yAxisGroupRight.selectAll("text")
          .style("font-size", "12px")
          .style("fill", "#6F6F6F")
          .style("text-anchor", "start"); // 讓文字靠左對齊
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.d3-my-cloth-statistics {
  .chart {
    display: flex;
    width: 330px;
    height: 100%;
    .y-axis {
      width: 36px;
      position: relative;
      flex-shrink: 0; /* 防止壓縮 */
    }
    .chart-body {
      overflow-x: auto; /* 滾動條 */
      flex-grow: 1; /* 保持容器寬度 */
      width: 100%;
    }
  }
  .chart-mark-list {
    max-width: 330px;
    display: flex;
    gap: 1rem;
    justify-content: center;
    .chart-mark-item {
      display: flex;
      align-items: center;
      gap: .5rem;
      .chart-mark-dot {
        width: .5rem;
        height: .5rem;
        background: $color-black;
        border-radius: 100%;
        &.add {
          background: #F7D88F;
        }
        &.out {
          background: #B1D8BD;
        }
        &.all {
          background: #E96D45;
        }
      }
      span {
        color: $typography-secondary-default;
      }
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.d3-my-cloth-statistics {

}
</style>
  
import { render, staticRenderFns } from "./D3MyClothStatistics.vue?vue&type=template&id=99b8748e&scoped=true"
import script from "./D3MyClothStatistics.vue?vue&type=script&lang=js"
export * from "./D3MyClothStatistics.vue?vue&type=script&lang=js"
import style0 from "./D3MyClothStatistics.vue?vue&type=style&index=0&id=99b8748e&prod&lang=scss&scoped=true"
import style1 from "./D3MyClothStatistics.vue?vue&type=style&index=1&id=99b8748e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99b8748e",
  null
  
)

export default component.exports
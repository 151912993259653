<template>
  <div class="test fill-parent">
<!--    <input type="file" ref="inputFile" @input="inputFile"/>-->
    <D3MyClothStatistics :data="myStatisticsData" />
    <D3MyClothYearStatistics :data="myYearStatisticsData" />
    <D3PercentageDonutChart :percentage="75" :size="72" :thickness="6" />
    <D3PercentageDistributionDonutChart :data="donutChartData" />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex';
// import imglyRemoveBackground from "@imgly/background-removal"
import D3MyClothStatistics from "@/views/test/D3MyClothStatistics.vue";
import D3MyClothYearStatistics from "@/views/test/D3MyClothYearStatistics.vue";
import D3PercentageDonutChart from "@/views/test/D3PercentageDonutChart.vue";
import D3PercentageDistributionDonutChart from "@/views/test/D3PercentageDistributionDonutChart.vue";

export default {
  name: 'Test',
  data() {
    return {
      file: null,
      myStatisticsData: [
        { all: 109, new: 10, out: 5, month: 10, year: 2023 },
        { all: 111, new: 2, out: 0, month: 11, year: 2023 },
        { all: 109, new: 1, out: 3, month: 12, year: 2023 },
        { all: 110, new: 1, out: 0, month: 1, year: 2024 },
        { all: 112, new: 3, out: 1, month: 2, year: 2024 },
        { all: 111, new: 1, out: 2, month: 3, year: 2024 },
        { all: 106, new: 0, out: 5, month: 4, year: 2024 },
        { all: 106, new: 1, out: 1, month: 5, year: 2024 },
        { all: 108, new: 2, out: 0, month: 6, year: 2024 },
        { all: 113, new: 7, out: 2, month: 7, year: 2024 },
        { all: 114, new: 2, out: 1, month: 8, year: 2024 },
        { all: 110, new: 3, out: 7, month: 9, year: 2024 },
      ],
      myYearStatisticsData: [
        { new: 35, out: 23, year: 2022 },
        { new: 31, out: 29, year: 2023 },
        { new: 21, out: 17, year: 2024 },
        { new: 21, out: 17, year: 2025 },
        { new: 21, out: 17, year: 2026 },
        { new: 21, out: 17, year: 2027 },
      ],
      chartData: [
        { month: 'a', value: 109 },
        { month: 'b', value: 111 },
        { month: 'c', value: 109 },
        { month: 'd', value: 110 },
        { month: 'e', value: 112 },
        { month: '6', value: 111 },
        { month: '7', value: 106 },
        { month: '8', value: 106 },
        { month: '9', value: 108 },
        { month: '10', value: 113 },
        { month: '11', value: 114 },
        { month: '12', value: 110 },
      ],
      donutChartData: [
        { name: '二手衣', percentage: 65 },
        { name: '新衣', percentage: 20 },
        { name: '無數據', percentage: 15 },
      ],
    };
  },
  components: {
    D3MyClothStatistics,
    D3MyClothYearStatistics,
    D3PercentageDonutChart,
    D3PercentageDistributionDonutChart,
  },
  computed: {
    ...mapState(['user', 'clothCategories']),
    
	},
  watch: {
    
  },
  async mounted() {
    // const imgSrc = 'https://walkingcloset.s3.ap-northeast-3.amazonaws.com/1697130297729.png';
    // imglyRemoveBackground(imgSrc).then((blob) => {
    //   const url = URL.createObjectURL(blob);
    //   console.log(url);
    // })
    if(this.user.serial !== 516) {
      await this.$router.push({ name: 'Home' });
    }
	},
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'showMsgModal', 'updateCloth']),
    async inputFile() {
      const file = this.$refs.inputFile.files[0];
      const blob = await this.readFileToBlobPromise(file);
      console.log(blob);
    },
    readFileToBlobPromise(file) {
      return new Promise((result, reject) => {
        const reader = new FileReader();
        reader.onload = function(e) {
          const blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
          result(blob);
        };
        reader.onerror = (e) => {
          reject(e);
        }
        reader.readAsArrayBuffer(file);
      });
    },
    // removeBgPromise(blob) {
    //   return new Promise((result) => {
    //     imglyRemoveBackground(blob).then((resBlob) => {
    //       // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
    //       result(resBlob);
    //     })
    //   });
    // },
  }
}
</script>

<style lang="scss" scoped>
  .fill-parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
  }

</style>
